import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Unauthorized from "./Unauthorized";

// For routes that can only be accessed by users with specific roles or in a specific usersArray
const CustomGuard = ({ children, rolesArray = [], usersArray = [] }) => {
  const { user } = useAuth0();

  if (!user) return <Unauthorized />;

  const roles = user[`${process.env.REACT_APP_AUDIENCE}/roles`];

  const isInUsersArray = usersArray.includes(user.sub);

  const hasRequiredRole =
    roles && roles.some((role) => rolesArray.includes(role));

  if (!hasRequiredRole && !isInUsersArray) {
    return <Unauthorized />;
  }

  return children;
};

export default CustomGuard;
